<template>
  <div class="">
    <trac-modal
      v-if="topupLoyatyModalCtrl"
      @close="
        forStoreCredit = false;
        topupLoyatyModalCtrl = false;
      "
    >
      <div class="mt-8">
        <h2 class="text-gray-700">
          Adjust points for
          {{ customer.first_name || "" + " " + customer.last_name || "" }}
        </h2>
        <trac-input
          class="my-6"
          :placeholder="forStoreCredit ? 'Store Credit' : 'Loyalty'"
          v-model.number="topUpData"
        ></trac-input>
        <!-- <div class="">
          <div class="w-full flex my-4">
            <trac-radio
              value="default"
              name="loyalty-data"
              :selectedVal="'increase'"
              @change="layaltyPointsOp = 'increase'"
              ><p class="text-xs">Increase</p></trac-radio
            >
          </div>
          <div class="w-full flex my-4">
            <trac-radio
              value="default"
              name="loyalty-data"
              :selectedVal="'decrease'"
              @change="layaltyPointsOp = 'decrease'"
              ><p class="text-xs">Decrease</p></trac-radio
            >
          </div>
        </div> -->

        <div class="flex justify-between">
          <trac-button
            :disabled="topUpData === ''"
            @button-clicked="
              forStoreCredit
                ? topupCustomerStoreCredit('decrease')
                : topupCustomerLoyalty('decrease')
            "
            class="mt-3 block"
            >DEDUCT</trac-button
          >
          <trac-button
            :disabled="topUpData === ''"
            @button-clicked="
              forStoreCredit
                ? topupCustomerStoreCredit('increase')
                : topupCustomerLoyalty('increase')
            "
            class="mt-3 block"
            >ADD</trac-button
          >
        </div>
      </div>
    </trac-modal>
    <main class="md:ml-12 mr-4 md:mr-0" v-if="dataLoaded">
      <trac-back-button>{{
        customer.routeFrom ? customer.routeFrom : "Customers"
      }}</trac-back-button>
      <ul class="flex-col mt-12 mb-8">
        <!-- <li>Customers</li> -->
        <li class="font-bold mt-4 text-2xl">Customer Details</li>
      </ul>

      <div class="grid lg:gap-5 grid-cols-1 md:grid-cols-2">
        <div class="">
          <div
            class="flex-wrap flex flex-row bg-white px-8 py-8 rounded-lg overflow-hidden big-shadow mb-4 md:mb-0"
          >
            <div class="md:w-6/12">
              <span class="align-text-bottom font-semibold">{{
                (customer.first_name || "") + " " + (customer.last_name || "")
              }}</span>
            </div>
            <div class="ml-auto cursor-pointer" @click="gotoEditCustomer">
              <img src="./../../assets/svg/edit.svg" alt="" />
            </div>
            <ul class="w-full mt-10">
              <li v-if="customer.email || customer.email !== ''">
                <div class="grid md:flex flex-row md:w-10/12">
                  <div class="flex-column md:w-8/12 mb-4 md:mb-0">
                    <ul v-if="customer.email">
                      <li class="text-xs text-primaryGray">Email:</li>
                      <li class="text-black text-xs">
                        {{ customer.email }}
                      </li>
                    </ul>
                  </div>
                  <div class="flex-column">
                    <ul v-if="customer.phone">
                      <li class="text-xs text-primaryGray">Phone:</li>
                      <li class="text-black text-xs">
                        {{ customer.phone }}
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              <li
                class="text-xs text-primaryGray mt-5"
                v-if="
                  (customer.address.state && customer.address.state !== '') ||
                  (customer.address.country && customer.address.country !== '')
                "
              >
                <div class="grid md:flex flex-row md:w-10/12">
                  <div class="flex-column md:w-8/12">
                    <ul v-if="getDOB">
                      <li class="text-xs text-primaryGray">DOB:</li>
                      <li class="text-black text-xs">
                        {{ moment(getDOB).format("Do MMMM") }}
                      </li>
                    </ul>
                  </div>
                  <div class="flex-column">
                    <ul>
                      <li class="text-xs text-primaryGray">Location:</li>
                      <li class="text-black text-xs">
                        <span>
                          {{ customer.address.state }}
                          <span
                            v-if="
                              customer.address.state ||
                              customer.address.state !== ''
                            "
                          >
                          </span
                          >{{ customer.address.country }}</span
                        >
                      </li>
                    </ul>
                  </div>
                </div>
                <!--            <div class="flex flex-row">-->
                <!--              <span class="w-2/12  text-xs text-primaryGray"-->
                <!--                >Location:</span-->
                <!--              >-->
                <!--              <span class="font-semibold text-black text-xs"-->
                <!--                >{{ customer.address.state-->
                <!--                }}<span-->
                <!--                  v-if="customer.address.state || customer.address.state !== ''"-->
                <!--                  >,</span-->
                <!--                >-->
                <!--                {{ customer.address.country }}</span-->
                <!--              >-->
                <!--            </div>-->
              </li>
              <li class="mt-5 md:w-10/12">
                <ul>
                  <li class="text-xs text-primaryGray">Notes:</li>
                  <li class="text-black text-xs">
                    {{ customer.notes || "No note." }}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

        <div class="bg-white px-8 py-8 rounded-lg big-shadow">
          <div class="flex justify-end">
            <div class="ml-auto">
              <img
                class="cursor-pointer"
                @click="showSecret = false"
                v-if="showSecret"
                src="./../../assets/svg/eye-closed.svg"
                alt="eye closed"
              />
              <img
                class="cursor-pointer"
                @click="showSecret = true"
                v-else
                src="./../../assets/svg/eye-open.svg"
                alt="eye opened"
              />
            </div>
          </div>
          <div class="md:w-10/12">
            <div class="w-full grid grid-cols-1 md:grid-cols-2">
              <ul class="mb-4 md:mb-0">
                <div class="">
                  <li class="text-xs text-primaryGray font-normal">
                    Customer Balance
                  </li>
                  <li class="font-bold text-sm" v-if="showSecret">
                    {{ customer.balance | formatPrice }}
                  </li>
                  <li class="font-bold text-sm" v-else>
                    {{ "XXXXXX" }}
                  </li>
                </div>
              </ul>
              <ul v-if="businesCustomerLoyalty">
                <li class="text-xs text-primaryGray font-normal">
                  Customer Loyalty Balance
                </li>
                <li class="font-bold text-sm" v-if="showSecret">
                  {{ customerLoyaltyData.Points | formatPrice }}
                </li>
                <li class="font-bold text-sm" v-else>
                  {{ "XXXXXX" }}
                </li>
                <li v-if="showSecret">
                  <!-- <button
                    class="text-primaryBlue text-xs cursor-pointer"
                    @click="topupLoyatyModalCtrl = true"
                  >
                    Topup Loyalty Points
                  </button> -->
                </li>
              </ul>
            </div>

            <div class="w-full grid grid-cols-1 md:grid-cols-2 mt-4 md:mt-10">
              <ul class="mb-4 md:mb-0">
                <li class="text-xs text-primaryGray font-normal">
                  Total Lifetime Balance
                </li>
                <li class="font-bold text-sm" v-if="showSecret">
                  {{ customer.lifeTimeBalance | formatPrice }}
                </li>
                <li class="font-bold text-sm" v-else>
                  {{ "XXXXXX" }}
                </li>
              </ul>
              <ul v-if="customerStoreCreditData">
                <li class="text-xs text-primaryGray font-normal">
                  Customer Store Credit
                </li>
                <li class="font-bold text-sm" v-if="showSecret">
                  {{ customerStoreCreditData.Credit | formatPrice }}
                </li>
                <li class="font-bold text-sm" v-else>
                  {{ "XXXXXX" }}
                </li>
                <!-- <li v-if="customerStoreCreditData.Data"> -->
                <li v-if="showSecret" class="mt-4 md:mt-0">
                  <button
                    class="text-primaryBlue text-xs cursor-pointer"
                    @click="fetchAllStores"
                  >
                    Topup Store Credit Points
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="">
        <div
          class="grid grid-cols-1 md:grid-cols-2 w-full mt-5 bg-white px-8 py-8 rounded-lg overflow-hidden big-shadow"
        >
          <div>
            <div class="grid grid-cols-2 border-b pb-6">
              <span class="font-semibold text-sm mr-auto"
                >Top items by purchase</span
              >
              <trac-button class="font-bold text-xs ml-auto" variant="icon"
                >VIEW ALL
              </trac-button>
            </div>
            <div>
              <ul>
                <li v-for="(item, i) in topItems" :key="i" class="border-b">
                  <div class="each-top-item grid content-center">
                    <trac-image-placeholder
                      :name="item.name"
                      :size="'h-16'"
                      :imgURL="item.product_image"
                    ></trac-image-placeholder>
                    <span class="my-auto text-xs capitalize">{{
                      item.name
                    }}</span>
                    <span class="ml-auto my-auto font-bold text-xs">{{
                      item.amount || 0 | formatPrice
                    }}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="md:ml-6 mt-8 md:mt-0 border rounded-lg overflow-hidden">
            <table class="w-full table-auto">
              <thead class="bg-blue-100">
                <tr class="h-20">
                  <th class="pl-5 text-left text-xs font-semibold">
                    RECENT TRANSACTIONS
                  </th>
                  <th class="text-center text-xs font-semibold">
                    <trac-button class="font-bold text-xs" variant="icon"
                      >VIEW ALL
                    </trac-button>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  :class="index % 2 === 0 ? '' : 'bg-gray-100'"
                  v-for="(transaction, index) in customer.recentTransactionData"
                  class="h-20"
                  :key="index"
                >
                  <td class="text-xs font-bold">
                    <ul class="pl-5">
                      <li class="text-xs">
                        {{ transaction.remition | formatPrice }}
                      </li>
                      <li class="text-xs text-primaryGray">
                        {{ transaction.created_at | formartTime }}
                      </li>
                    </ul>
                  </td>
                  <td class="text-xs text-center">
                    {{ transaction.items.length }} item(s)
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </main>
    <div class="w-full h-screen" v-if="isLoading">
      <trac-loading />
    </div>
  </div>
</template>

<script>
import {
  DELETE_LOCAL_DB_DATA,
  GET_LOCAL_DB_DATA,
  GET_USER_BUSINESS_DATA,
  GET_USER_BUSINESS_ID,
  GET_USER_TOKEN,
  SAVE_LOCAL_DB_DATA,
} from "../../browser-db-config/localStorage";
import moment from "moment";
import {
  ADD_DATA_TO_DB,
  CONNECT_TO_DB,
  GET_DB_DATA,
  GET_OBJECT_STORE,
  SAVE_OBJECT_STORE,
} from "../../browser-db-config/indexedDB";

import { eventBus } from "./../../main";

export default {
  name: "CustomerDetails",
  data() {
    return {
      isLoading: false,
      forStoreCredit: false,
      topupLoyatyModalCtrl: false,
      dataLoaded: false,
      showSecret: false,
      businesCustomerLoyalty: false,
      customer: null,
      topUpData: "",
      customerLoyaltyData: null,
      customerStoreCreditData: null,
      topItems: null,
      selectedStore: null,
      moment,
      layaltyPointsOp: "increase",
    };
  },
  computed: {
    getDOB() {
      if (this.customer.day_month) {
        const day = this.customer.day_month.split("-")[0];
        const month = +this.customer.day_month.split("-")[1] - 1;
        const year = new Date().getFullYear();
        const date = new Date(year, month, day);
        return date;
      }
    },
  },
  async created() {
    this.isLoading = true;
    this.businesCustomerLoyalty = GET_USER_BUSINESS_DATA().loyalty_active;
    await this.getCustomer();
    await this.collateCustomerBalance();
    this.dataLoaded = true;
    this.isLoading = false;
  },
  methods: {
    gotoEditCustomer() {
      SAVE_LOCAL_DB_DATA("customer-to-update", this.customer);
      this.$router.push({ name: "AddCustomer" });
    },

    async getCustomer() {
      this.customer = GET_LOCAL_DB_DATA("customer-details");

      if (!this.customer) {
        this.$router.replace({ name: "Customers" });
        return;
      }

      await this.getCustomerSalesDetails();
      await this.getCustomerInvoicesDetails();

      // Check Store credit
      let response = await this.getStoreCreditDetails();
      let payload = await response.json();

      // If token has expired get another
      if (response.status === 401) {
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Content-Type", "application/json;charset=utf-8");
        myHeaders.append("Authorization", `Bearer ${GET_USER_TOKEN()}`);

        const secondaryTokenUpdateRes = await fetch(
          `https://tractionapp-stage.herokuapp.com/auth/loyalty/access`,
          {
            method: "POST",
            headers: myHeaders,
          }
        );

        const secondaryTokenUpdateData = await secondaryTokenUpdateRes.json();

        // Update in localStorage
        SAVE_LOCAL_DB_DATA("secondary-auth-token", secondaryTokenUpdateData);

        await SAVE_OBJECT_STORE(
          'secondary_token_db',
          "secondary_token",
          secondaryTokenUpdateData.data
        );

        // Make the same call with the new token
        // Check Store credit
        response = await this.getStoreCreditDetails();
        payload = await response.json();
      }

      if (payload.Success) {
        // Set local data
        // alert(payload.Message);
        this.allowStoreCredit = payload.Data.active;
        this.customerStoreCreditData = payload.Data;

        if (this.customerStoreCreditData) {
          const storeCreditDataRes = await this.fetchStoreCreditCummulation();

          if (storeCreditDataRes.Success) {
            if (!storeCreditDataRes.Data) {
              storeCreditDataRes.Data = [];
            }
            this.customerStoreCreditData = storeCreditDataRes.Data.find(
              (data) => data.CustomerID === this.customer._id
            ) || { Credit: 0 };
          } else {
            this.customerStoreCreditData = { Credit: 0 };
            // alert(storeCreditDataRes.Message || storeCreditDataRes.Errors);
          }
        }
      } else {
        // eventBus.$emit("trac-alert", { message: payload.Message });
      }

      // fetch loyalty data
      if (this.businesCustomerLoyalty) {
        const loyaltyDataRes = await this.fetchLoyaltyCummulation();

        if (loyaltyDataRes.Success) {
          this.customerLoyaltyData = (loyaltyDataRes.Data || []).find(
            (data) => data.CustomerID === this.customer._id
          ) || { Points: 0 };
        } else {
          this.customerLoyaltyData = { Points: 0 };
          // alert(loyaltyDataRes.Message || loyaltyDataRes.Errors);
        }
      }

      await this.topPuchases();
      await this.recentTransactions();
    },
    getStoreCreditDetails() {
      const token = GET_LOCAL_DB_DATA("secondary-auth-token").data.Token;

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      return fetch(
        `https://tractionloyalty-stage.herokuapp.com/store_credit/${GET_USER_BUSINESS_ID()}`,
        {
          method: "GET",
          headers: myHeaders,
        }
      );
    },
    async getCustomerSalesDetails() {
      await this.$store.dispatch(
        "FETCH_CURRENT_CUSTOMER_SALES_RECORDS",
        this.customer
      );
    },
    async getCustomerInvoicesDetails() {
      await this.$store.dispatch(
        "FETCH_CURRENT_CUSTOMER_INVOICES_RECORDS",
        this.customer
      );
    },
    async collateCustomerBalance() {
      await this.$store.dispatch("FETCH_ALL_INVOICES");
      const res = this.$store.getters["GET_ALL_INVOICES"].data || {
        invoice: [],
      };

      const invoices = res.invoice || [];

      invoices.forEach((invoice) => {
        if (this.customer._id === invoice.client) {
          this.customer.balance +=
            isNaN(+invoice.balance.outstanding.amount) ||
            +invoice.balance.outstanding.amount === undefined ||
            +invoice.balance.outstanding.amount === null
              ? 0
              : +invoice.balance.outstanding.amount;
        }
      });
    },
    topupCustomerStoreCredit(pointsOption) {
      this.isLoading = true;

      const token = GET_LOCAL_DB_DATA("secondary-auth-token").data.Token;

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${token}`);

      fetch(
        `https://tractionloyalty-stage.herokuapp.com/customer_store_credit/${GET_USER_BUSINESS_ID()}/${
          this.customer._id
        }`,
        {
          method: "POST",
          headers: myHeaders,
          // mode: "no-cors",
          body: JSON.stringify({
            credit: this.topUpData,
            op: pointsOption,
          }),
        }
      )
        .then((res) => res.json())
        .then(async (payload) => {
          if (payload.Success) {
            let updateFlag = false;
            this.customerStoreCreditData.Credit = payload.Data.Credit;
            let offlineStoreCredit = await GET_OBJECT_STORE(
              'offline_db_all_store_credits',
              "all_store_credits"
            );

            offlineStoreCredit.Data = offlineStoreCredit.Data || [];

            offlineStoreCredit.Data.forEach((sc, i) => {
              if (sc.CustomerID === this.customer._id) {
                sc.Credit = payload.Data.Credit;
                updateFlag = true;
              }

              if (!updateFlag && offlineStoreCredit.Data.length - 1 === i) {
                offlineStoreCredit.Data.push({
                  BusinessID: GET_USER_BUSINESS_ID(),
                  Credit: payload.Data.Credit,
                  CustomerID: this.customer._id,
                });
              }
            });

            await SAVE_OBJECT_STORE(
              'offline_db_all_store_credits',
              "all_store_credits",
              offlineStoreCredit
            );

            this.topupLoyatyModalCtrl = false;
            // alert(payload.Message);
            eventBus.$emit("trac-alert", { message: payload.Message });
            this.topUpData = "";
          } else {
            // alert(payload.Message || payload.Errors);
            eventBus.$emit("trac-alert", {
              message: payload.Message || payload.Errors,
            });
          }
          this.isLoading = false;
        })
        .catch((err) => {
          // (err);
          // alert(err);
          eventBus.$emit("trac-alert", { message: err });
          this.isLoading = false;
        });
    },
    topupCustomerLoyalty(pointsOption) {
      this.isLoading = true;

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      fetch(
        `https://tractionloyalty-stage.herokuapp.com/customer_points/${GET_USER_BUSINESS_ID()}/${
          this.customer._id
        }`,
        {
          method: "POST",
          headers: myHeaders,
          // mode: "no-cors",
          body: JSON.stringify({
            point: this.topUpData,
            op: pointsOption,
          }),
        }
      )
        .then((res) => res.json())
        .then(async (payload) => {
          if (payload.Success) {
            this.customerLoyaltyData.Points = payload.Data.Points;

            // fetch from db
            // const offlineLoyaltyPointsDB = await CONNECT_TO_DB(
            //   "offline_db_all_loyalty_points",
            //   1,
            //   "all_loyalty_points"
            // );
            let offlineAllLoyaltyPoints = await GET_OBJECT_STORE(
              'offline_db_all_loyalty_points',
              "all_loyalty_points"
            );

            offlineAllLoyaltyPoints.Data = offlineAllLoyaltyPoints.Data.map(
              (loyalty) => {
                if (loyalty.CustomerID === this.customer._id) {
                  loyalty.Points = payload.Data.Points;
                }
                return loyalty;
              }
            );

            await SAVE_OBJECT_STORE(
              'offline_db_all_loyalty_points',
              "all_store_credits",
              offlineAllLoyaltyPoints
            );

            this.topupLoyatyModalCtrl = false;
            // alert(payload.Message);
            eventBus.$emit("trac-alert", { message: payload.Message });
            this.topUpData = "";
          } else {
            // alert(payload.Message || payload.Errors);
            eventBus.$emit("trac-alert", {
              message: payload.Message || payload.Errors,
            });
          }

          this.isLoading = false;
          this.topUpData = "";
        })
        .catch((err) => {
          // (err);
          // alert(err);
          eventBus.$emit("trac-alert", { message: err });
          this.isLoading = false;
        });
    },
    async fetchLoyaltyCummulation() {
      let offlineAllLoyaltyPoints = await GET_OBJECT_STORE(
        'offline_db_all_loyalty_points',
        "all_loyalty_points"
      );

      return offlineAllLoyaltyPoints;
    },
    fetchAllStores() {
      this.forStoreCredit = true;
      this.topupLoyatyModalCtrl = true;
    },
    async fetchStoreCreditCummulation() {
      let offlineStoreCredit = await GET_OBJECT_STORE(
        'offline_db_all_store_credits',
        "all_store_credits"
      );

      return offlineStoreCredit;
    },
    topPuchases() {
      const currentCustomerSalesRecords = this.$store.getters[
        "GET_CUSTOMER_SALES_RECORDS"
      ];
      const currentCustomerInvoicesRecords = this.$store.getters[
        "GET_CUSTOMER_INVOICES_RECORDS"
      ];

      let sales = [];
      let invoices = [];

      const allSalesHistory = this.$store.getters["GET_ALL_SALES_HISTORY"];
      const allInvoices = this.$store.getters["GET_ALL_INVOICES"];

      if (currentCustomerSalesRecords) {
        currentCustomerSalesRecords.forEach((item) =>
          sales.push(...item.items)
        );
      }

      if (currentCustomerInvoicesRecords) {
        currentCustomerInvoicesRecords.forEach((item) =>
          invoices.push(...item.items)
        );
      }

      sales = sales.map((sale) => {
        return {
          name: sale.name,
          amount: sale.price ? sale.price : 0,
          product_image: sale.product_image,
        };
      });

      invoices = invoices.map((invoice) => {
        return {
          name: invoice.name,
          amount: invoice.total_amount ? invoice.total_amount : 0,
        };
      });

      this.customer = {
        ...this.customer,
        // lifeTimeBalance: this.calcLifeTimeBalance(invoices, sales),
        allItems: [...sales, ...invoices],
      };

      // Build the cummulation of all top products
      let dict = Object.create(null); // create an empty object

      this.topItems = this.customer.allItems.reduce(function (arr, o) {
        let current = dict[o.name]; // get the object from dict

        if (!current) {
          // if dict doesn't contain object
          current = Object.assign({}, o); // create a clone of the object - this prevents changing the original object
          arr.push(current); // push it to the array

          dict[o.name] = current; // add it to dict
        } else {
          // if dict contains the object
          current.amount += o.amount; // update the sum
        }
        return arr;
      }, []);

      this.topItems.forEach((tpItem) => {
        tpItem.name = tpItem.name
          .split("")
          .map((letter, index) => {
            return index === 0 ? letter.toUpperCase() : letter.toLowerCase();
          })
          .join("");
      });
      this.topItems = this.topItems.sort((a, b) =>
        a.amount > b.amount ? -1 : 1
      );
    },
    calcLifeTimeBalance(
      currentCustomerInvoicesRecords,
      currentCustomerSalesRecords
    ) {
      let invoiceTotal = 0;
      let salesTotal = 0;

      if (currentCustomerInvoicesRecords) {
        invoiceTotal = currentCustomerInvoicesRecords.reduce(
          (recordTotal, record) => {
            return (
              recordTotal +
              (record.items || []).reduce(
                (itemTotal, item) =>
                  itemTotal + (item.quantity || 1) * (item.unit_price || 1),
                0
              )
            );
          },
          0
        );
      }

      // console.log(currentCustomerSalesRecords);

      if (currentCustomerSalesRecords) {
        salesTotal = currentCustomerSalesRecords.reduce(
          (recordTotal, record) => {
            return (
              recordTotal +
              (record.items || []).reduce((itemTotal, item) => {
                return itemTotal + (item.quantity || 1) * (item.price || 1);
              }, 0)
            );
          },
          0
        );
      }

      return salesTotal + invoiceTotal;
    },
    async recentTransactions() {
      // const currentCustomerSalesRecords = this.$store.getters[
      //   "GET_CUSTOMER_SALES_RECORDS"
      // ];
      const currentCustomerInvoicesRecords = this.$store.getters[
        "GET_CUSTOMER_INVOICES_RECORDS"
      ];

      let sales;
      let invoices;

      const allSalesHistory = await this.$store.dispatch(
        "FETCH_SALES_HISTORY",
        { _id: "all" }
      );
      // const allSalesHistory = this.$store.getters["GET_ALL_SALES_HISTORY"];
      // const allInvoices = this.$store.getters["GET_ALL_INVOICES"];

      const recentTransactionData = [];

      if (allSalesHistory) {
        sales = (allSalesHistory.data
          ? allSalesHistory.data.salesHistory || []
          : []
        ).map((saleItem) => {
          if (
            (this.customer.email === saleItem.customer_email && saleItem.customer_id) ||
            ((this.customer.first_name || "") +
              " " +
              (this.customer.last_name || "") ===
              saleItem.customer_name && saleItem.customer_id) ||
            (this.customer._id === saleItem.customer_id && saleItem.customer_id)
          ) {

            return {
              _id: saleItem._id,
              created_at: saleItem.created_at
                ? saleItem.created_at
                : saleItem.offline_date + "Z",
              payment_method: saleItem.payment_method,
              remition: saleItem.total_price,
              items: saleItem.items || [],
            };
          }
        });
        sales = sales.filter((sale) => sale !== undefined);
        recentTransactionData.push(...sales);
      }

      if (currentCustomerInvoicesRecords) {
        invoices = currentCustomerInvoicesRecords.filter(
          (inv) => (inv.balance.outstanding.amount || 0) > 0
        );

        invoices = invoices.map((invoiceItem) => {
          return {
            _id: invoiceItem._id,
            created_at: invoiceItem.created_at,
            payment_method: invoiceItem.payment_method,
            remition: invoiceItem.balance.outstanding.amount || 0,
            // remition: invoiceItem.balance.total.amount || 0,
            items: invoiceItem.items,
          };
        });

        recentTransactionData.push(...invoices);
      }

      this.customer = {
        ...this.customer,
        lifeTimeBalance: this.calcLifeTimeBalance(invoices, sales),
        recentTransactionData: recentTransactionData.sort((a, b) =>
          a.created_at > b.created_at ? -1 : 1
        ),
        // Condition got me here :(
        allItems:
          sales && invoices
            ? [...(sales || []), ...(invoices || [])]
            : invoices
            ? [...(invoices || [])]
            : sales
            ? [...(sales || [])]
            : [],
      };
    },
  },
};
</script>

<style scoped>
.each-top-item {
  grid-template-columns: 80px 1fr 0.8fr;
}
</style>
